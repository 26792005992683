<template>
	<div class="">
		<Dialog :dialog="dialog" :dialogWidth="920" @close="$emit('close', true)">
			<template v-slot:title>
        <div class="d-flex align-center w-100">
          <span>
            Activity
          </span>
          <v-spacer></v-spacer>
          <v-text-field
            hide-details
            v-model="search"
            style="max-width: 200px"
            class="mr-2"
            :disabled="activityLoading"
            placeholder="Search..."
            outlined
            dense
            clearable
            v-on:click:clear="$emit('clear-search')"
          ></v-text-field>
          <div class="mr-2" style="max-width:250px">
            <DateRangePicker v-on:click:clear="$emit('clear-search')" :disabled="activityLoading" top-dense v-model="date_range" />
          </div>
          <v-btn :disabled="activityLoading" v-on:click="doSearch()" depressed color="blue darken-4" class="white--text">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </div>
      </template>
			<template v-slot:body>
				<div v-if="activities.length">
          <v-timeline dense align-top>
            <v-timeline-item v-for="(row, i) in activities" :key="i" :color="row.color" small reverse>
              <div class="pb-4">
                <div class="d-flex align-center">
                  <v-chip
                    class="white--text mr-4"
                    color="blue"
                    label
                    small
                    v-if="row.module"
                  >
                    {{row.module}}
                  </v-chip>
                  <div :class="`font-weight-bold`" v-text="row.date"></div>
                  <!-- <v-spacer></v-spacer> -->
                </div>
                <div class="mt-1">
                  <div class="font-weight-medium text-h6" v-if="row.title">{{ row.title }}</div>
                  <span v-html="row.description"/>
                  <div>
                    <em :class="`mt-2 font-weight`">IP Address : {{ row.ip_address }}</em>
                  </div>
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>    
        </div>
        <div v-else>
          <p v-if="activityLoading">Loading...</p>
          <p v-else>Nothing Found...</p>
        </div>
			</template>
			<template v-slot:action>
				<v-btn :disabled="activityLoading" depressed tile v-on:click="$emit('close', true)"> Cancel </v-btn>
			</template>
		</Dialog>
	</div>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import DateRangePicker from "@/view/components/DateRangePicker.vue"
export default {
	name: "ActivityDialog",
	components: {
		Dialog,
    DateRangePicker
	},
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
    activityLoading: {
      type: Boolean,
      default: false,
    },
    activities: {
      type: Array,
      default() {
        return []
      },
    },
	},
	data() {
		return {
      date_range:[],
      search: null,
		};
	},
	methods: {
    doSearch() {
      this.$emit('search', {
        date_range: this.date_range,
        search: this.search,
      });
    }
  },
	mounted() {},
};
</script>
