<template>
	<v-container fluid class="bg-white h-100">
		<v-layout class="border-bottom py-2">
			<div class="text-h5 d-flex align-center justify-center">All Users
				<div class="d-flex align-center justify-center">
					<v-chip class="ml-4" color="green white--text" rounded left>{{client.active_user}}</v-chip>
					<span class="mx-1">/</span>
					<v-chip color="blue white--text" rounded left>{{client.total_user}}</v-chip>
				</div>
			</div>
			<v-spacer></v-spacer>
			<v-btn depressed outlined color="blue darken-4" class="white--text" @click="$router.go(-1)">
				<v-icon left>mdi-arrow-left</v-icon>
				Back
			</v-btn>
		</v-layout>
		<v-layout>
			<v-col md="1" class="px-0">
				<img
					height="60"
					contain
					:src="client.client_logo"
				/>
			</v-col>
			<v-col md="11" class="px-0">
				<v-row>
					<v-col md="4">
						<div class="text-h5 blue--text text-truncate">
							<ShowValue :object="client" object-key="client_name" label="company name"/>
						</div>
						<div class="pt-2 text-truncate">
							Display Name : <span class="font-weight-medium"><ShowValue :object="client" object-key="display_name" label="display name"/></span>
						</div>
						<div class="py-2 text-truncate">
							Claim Code : <v-btn depressed style="height: 20px !important;" color="blue darken-4 white--text" class="bold-600">{{ client.client_claim_code }}</v-btn>
						</div>
						<div class="text-truncate">
							Login URL : <a :href="`https://www.${client.client_sub_domain}.${client.client_domain}`" target="_blank" class="blue--text text--darken-4 bold-600">Click here to login</a>
						</div>
					</v-col>
					<v-col md="4">
						<table>
							<tr>
								<td height="25px" width="100px">Mobile No.</td>
								<td align="center" width="20px">:</td>
								<td class="font-weight-medium">
									<ShowValue :object="client" object-key="mobile_no" label="mobile no"/>
								</td>
							</tr>
							<tr>
								<td height="25px">Email Address</td>
								<td align="center">:</td>
								<td class="font-weight-medium">
									<ShowValue :object="client" object-key="client_email" label="email address"/>
								</td>
							</tr>
							<tr>
								<td height="25px">Created Date</td>
								<td align="center">:</td>
								<td class="font-weight-medium">
									{{ formatDateTime(client.added_at) }}
								</td>
							</tr>
						</table>
					</v-col>
					<v-col md="4">
						<table>
							<tr>
								<td height="25px" width="60px" valign="top">Package</td>
								<td valign="top" align="center" width="20px">:</td>
								<td valign="top">
									<div class="d-flex align-center">
										<v-chip class="mr-2" label> {{ clientPackage.package_name }} </v-chip>
									</div>
								</td>
							</tr>
							<tr v-if="client.renewal_date">
								<td height="25px">Renew</td>
								<td align="center" >:</td>
								<td class="font-weight-medium">
									{{ formatDate(client.renewal_date) }}
									<span class="red--text font-weight-medium" v-if="client.renewal_days < 100">({{client.renewal_days}} left)</span>
								</td>
							</tr>
						</table>
					</v-col>
				</v-row>
			</v-col>
		</v-layout>
		<v-col md="12" class="pa-0 bt-table" ref="btt_height">
			<table class="table-list">
				<thead class="text-uppercase">
					<th width="60">Image</th>
					<!-- <th min-width="150">Company name</th> -->
					<th min-width="150">Display name</th>
					<th min-width="100">Mobile no</th>
					<th min-width="150">Email address</th>
					<th min-width="150">Last login</th>
					<th min-width="150">Created date</th>
				</thead>
				<tbody>
					<tr v-for="(row, index) in clientUsers" :key="`client-user-${index}`" class="cursor-pointer" v-on:click="showActivity(row)">
						<td>
							<v-avatar size="40">
								<v-img :src="row.user_image" alt="profile-image" />
							</v-avatar>
						</td>
						<td>{{ row.display_name }}</td>
						<td>{{ row.mobile_no }}</td>
						<td>{{ row.user_email }}</td>
						<td>
							<div>
								<v-icon size="16">mdi-calendar</v-icon>
								{{ formatDateTime(row.last_login_at) }}
							</div>
							<div class="blue--text">
								<v-icon size="16">mdi-calendar</v-icon>
								<span v-if="row.last_login_in_days < 1">today</span>
								<span v-else-if="row.last_login_in_days == 1">yesterday</span>
								<span v-else>{{ row.last_login_in_days }} days ago</span>
							</div>
						</td>
						<td>{{ formatDateTime(row.added_at) }}</td>
					</tr>
				</tbody>
			</table>
		</v-col>
		<Activity v-on:clear-search="doActivitySearch()" :activity-loading="activityLoading" v-on:close="closeActivity()" v-on:search="doActivitySearch($event)" :activities="clientActivities" :dialog="activityDialog" />
	</v-container>
</template>

<script>
import heightMixin from "@/core/mixins/height.mixin";
import Activity from "./Activity.vue";
import { QUERY } from "@/core/services/store/request.module";
import ShowValue from "@/view/components/ShowValue";

export default {
	name: "adminUsers",
	mixins: [heightMixin],
	data() {
		return {
			clientUUID: null,
			clientUserUUID: null,
			skipBottomHeight: 68,
			activityDialog: false,
			activityLoading: false,
			clientLoading: false,
			shortByList: [
				{
					text: "All Users",
					value: "all",
				},
				{
					text: "This Week",
					value: "this_week",
				},
				{
					text: "This Month",
					value: "this_month",
				},
				{
					text: "This Year",
					value: "this_year",
				},
			],
			clientUsers: [],
			clientActivities: [],
			clientPackage: {},
			client: {}
		};
	},
	methods: {		
		routeToLogin() {
			const routeData = this.$router.resolve({name: 'routeName', query: {data: "someData"}});
			window.open(routeData.href, '_blank');
		},
		closeActivity() {			
			this.clientActivities = [];
			this.activityDialog = false;
			this.clientUserUUID = null;
		},
		doActivitySearch(param = {}) {
			this.clientActivities = [];
			this.getActivities(param);
		},
		getActivities(query) {
			if (!this.clientUUID || !this.clientUserUUID) {
				return false;
			}

			this.activityLoading = true;

			this.$store.dispatch(QUERY, { url: `clients/${this.clientUUID}/activity/${this.clientUserUUID}`, data: query })
				.then((response) => {
					this.clientActivities = response;
					this.activityDialog = true;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.activityLoading = false;
				});
		},
		getClientUsers() {
			this.clientUsers = [];
			this.clientPackage = {};
			this.client = {};

			this.clientLoading = true;

			this.$store.dispatch(QUERY, { url: `clients/${this.clientUUID}` })
				.then((response) => {
					this.clientUsers = response.users;
					this.clientPackage = response.package;
					this.client = response.client;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.clientLoading = false;
				});
		},
		showActivity({ uuid }) {
			this.clientActivities = [];
			this.activityDialog = false;
			this.clientUserUUID  = uuid;
			this.getActivities({});
		}
	},
	mounted() {
		this.getClientUsers();
	},
	created() {
		this.clientUUID = this.$route.params.uuid;
		if(!this.clientUUID){
			this.goBack();
		}
	},
	components: {
		Activity,
		ShowValue,
	},
};
</script>
<style lang="scss">
@import "./styles.scss";
</style>
